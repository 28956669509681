<template>
  <div>
    <h3 class="mb-4 color-primary-light">{{ heading }}</h3>
    <div class="row mb-4">
      <div v-if="isImac" class="col-lg-2">
        <div class="image-container imac">
          <img src="@/assets/images/packaging/imac-1.jpg" alt="Image 1 for iMac" />
        </div>
        <div class="image-container imac">
          <img src="@/assets/images/packaging/imac-2.jpeg" alt="Image 2 for iMac" />
        </div>
        <div class="image-container imac">
          <img src="@/assets/images/packaging/imac-3.jpeg" alt="Image 3 for iMac" />
        </div>
      </div>
      <div class="col">
        <ul class="list-unstyled">
          <li class="mb-3" v-for="(instruction, index) in instructions" :key="index">
            <div class="d-flex align-items-start">
              <span class="color-primary-light">{{ `${index + 1}.` }}</span>
              <p class="mb-0" v-html="instruction">{{ instruction }}</p>
            </div>
          </li>
        </ul>
        <p class="note" v-if="note"><strong>{{ note }}</strong></p>
        <div class="image-division"  v-if="!isImac && !isForPackageProductVar4 && !showBox">
          <div class="image-container" v-if="!isForPackageProductVar2">
            <img src="@/assets/images/packaging/all.jpg" alt="Image for non-iMac devices" />
          </div>

          <div class="image-container" v-if="isForPackageProductVar2 && !isImac" :class="(isForPackageProductVar2) ? 'float-left' : ''">
            <img style="max-width:120%;" src="@/assets/images/packaging/mail-box-5.png" alt="Image for Large Box" />
          </div>
          <div style='float:right;' v-if="isForPackageProductVar2 && !isImac">
            <img src="@/assets/images/devices/stickWand.jpg" alt="Vacuum Stick Wand" style="margin-left: 20%; height: 190px;" />
            <p> <strong> <u> ** PLEASE DO NOT SEND THE WAND ** </u> </strong> </p>
          </div>

        </div>
      </div>
    </div>
    <div class="row">
      <div class="col">
        <router-link class="btn btn-next bg-red" :to="{path: pages.sendDevice.route, query: {'type': this.selection } }"
          ><strong class="color-white">Next</strong></router-link
        >
      </div>
    </div>
  </div>
</template>

<script>
import { pages, devices } from '@/shared';

export default {
  data() {
    return {
      path: this.$route.fullPath.split('/'),
      selection: this.$route.query.type,
    }
  },
  props: {
    device: {
      default: null,
    }
  },
  computed: {
    pages() {
      if(this.path[1] === 'revive-dyson') {
        return pages['revive-dyson'].children;
      }
      return pages["revive-apple"].children;
    },
    isImac() {
      return this.device === 'imac';
    },
    isForPackageProductVar4() {
      if( ['air-purifiers','barrel-vacuums','upright-vacuums'].includes(this.device) ) {
      return true
      }
      return false;
    },
    isForPackageProductVar2() {
      if(this.$route.query.type === 'no' && this.device === 'stick-vacuums') {
        return true;
      }
      return false
    },
    devicekey() {
      if(this.path[1] === 'revive-dyson') {
        return Object.keys(devices.dyson).find((key) => devices.dyson[key].route === this.device);
      }
      return Object.keys(devices.apple).find((key) => devices.apple[key].route === this.device);
    },
    heading() {
      let heading = devices.apple[this.devicekey]?.packageDeviceHeading;
      if(this.path[1] === 'revive-dyson') {
        heading = devices.dyson[this.devicekey]?.packageDeviceHeading;
      }
      return heading;
    },
    instructions() {
      let instructions = devices.apple[this.devicekey]?.packagingInstructions
      if(this.path[1] === 'revive-dyson') {
        instructions = devices.dyson[this.devicekey]?.packagingInstructions;
        if( ["hair-dryer","air-wrap"].includes(this.device) && this.selection === 'no') {
          instructions = devices.dyson[this.devicekey]?.packagingInstructionsVar1;
        } else if ( ["hair-dryer", "air-wrap", "stick-vacuums"].includes(this.device) && this.selection === 'yes') {
          instructions = devices.dyson[this.devicekey]?.packagingInstructionsVar3;
        } else if (this.device === 'stick-vacuums' && this.selection === 'no') {
          instructions = devices.dyson[this.devicekey]?.packagingInstructionsVar2;
        }
      }
      return instructions;
    },
    note() {
      if(this.path[1] === 'revive-dyson') {
        return devices.dyson[this.devicekey]?.note;
        }
      return devices.apple[this.devicekey]?.note;
    },
    showBox() {
      if(['stick-vacuums','hair-dryer','air-wrap'].includes(this.device) && this.selection === 'yes') {
        return true
      }
      return false
    }
  },
};
</script>

<style scoped lang="scss">
ul {
  li {
    span {
      min-width: 25px;
      text-align: right;
      margin-right: 10px;
    }
  }
}

.note {
  border: 1px solid #723f98;
  padding: 10px;
  border-radius: 6px;
}

.image-container {
  width: 350px;
  display: flex;
  flex-shrink: 0;
  align-items: center;
  justify-content: center;
  margin: 0 auto 10px;
  @media (max-width: 575.98px) {
    width: 100%;
  }

  &.imac {
    width: 100%;
  }
  img {
    max-width: 100%;
  }
}

@media (max-width: 991.98px) {
  .row {
    flex-direction: column-reverse;
  }
}
.image-division{
  width:60%;
  margin:0 auto;
}
</style>
